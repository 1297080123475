import { useCallback } from 'react';
import { config } from '../../../config/config';
import { mbCoreApi } from '../api';
import { InfoPage } from '../../../models/info.model';

export const useFetchPages = () => {
  const func = useCallback(async (): Promise<InfoPage[]> => {
    try {
      console.log('fetching pages....');

      const response = await mbCoreApi.get(`${config.apiUrl}/v1/pages`);

      if (!response || !response.data) {
        return Promise.reject(new Error('useFetchPages: No data'));
      }

      return response.data;
    } catch (e: any) {
      throw new Error('useFetchPages:', e);
    }
  }, []);

  return {
    fetchPages: func,
  };
};
