import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
// import Backend from 'i18next-locize-backend';
// import Backend from 'i18next-fs-backend';

import LanguageDetector from 'i18next-browser-languagedetector';

// const locizeOptions = {
//   projectId: '73e8fdc0-c663-47de-8403-460870b32b2f', //process.env['I18N_PROJECTID'],
//   apiKey: '5ade9edf-31ac-4200-8b44-10100bd3cab5', //process.env['I18N_API_KEY'],
//   referenceLng: 'nb-NO',
// };

export const defaultNs = ['common']
const isProduction = process.env.NODE_ENV === 'production'
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    load: 'languageOnly',
    debug: !isProduction,
    ns: ['common', 'chatsPage', 'helpersPage', 'settingsPage', 'infoPage'],
    fallbackNS: 'common',
    fallbackLng: 'nb-NO',
    // backend: {
    //   loadPath: join(__dirname, 'public/locales/{{lng}}/{{ns}}.json')
    // },
    // saveMissing: true,
    // updateMissing: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
