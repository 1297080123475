import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { language, logOut, moon } from 'ionicons/icons';
import { useAppSettingsStore } from '../../../stores/appSettings.store';
import { AuthState, useAuthStore } from '../../../stores/auth.store';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { config } from '../../../config/config';
import { URLS } from '../../../routing/urls';

const Settings: React.FC = ({ chat, history }: any) => {
  const { t } = useTranslation(['common', 'settingsPage']);

  const setAuthState = useAuthStore((state) => state.setAuthState);
  const darkMode = useAppSettingsStore((state) => state.darkMode);
  const languageValue = useAppSettingsStore((state) => state.language);

  const toggleDarkModeHandler = useAppSettingsStore(
    (state) => state.toggleDarkMode,
  );

  const changeLanguage = useAppSettingsStore((state) => state.setLanguage);
  const changeLanguageHandler = (e) => changeLanguage(e.target.value);

  const gotoPushPage = () => {
    console.log('Goto push settings');
    console.log(history.push(URLS.APP_PUSH));
  }

  const logoutHandler = () => {
    setAuthState(AuthState.loggedOut);
  }
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>
            <h1 className="ion-no-margin">{t('settings')}</h1>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonItem lines="none">
            <IonIcon slot="start" icon={moon} />
            <IonLabel>{t('darkMode')}</IonLabel>
            <IonToggle
              slot="end"
              name="darkMode"
              checked={darkMode}
              onIonChange={toggleDarkModeHandler}
            />
          </IonItem>
          <IonItem>
            <IonIcon slot="start" icon={language} />
            <IonLabel>{t('selectLang', { ns: 'settingsPage' })}</IonLabel>
            <IonSelect
              onIonChange={changeLanguageHandler}
              value={languageValue}
            >
              <IonSelectOption value="nb-NO">Norsk Bokmål</IonSelectOption>
              <IonSelectOption value="nn-NO">Norsk Nynorsk</IonSelectOption>
              <IonSelectOption value="en">English</IonSelectOption>
              <IonSelectOption value="uk">Ukrainian</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonIcon slot="start" icon={logOut} />
            <IonButton
              slot="end"
              onClick={logoutHandler}
            >
              {t('logout')}
            </IonButton>
          </IonItem>
        </IonList>

        {!config.isProd ? (
          <>
            <IonLabel>Test Pages:</IonLabel>
            <br />
            <IonButton onClick={() => history.push(URLS.APP_PUSH)}>
              PushSettings
            </IonButton>
            <IonButton onClick={() => history.push(URLS.APP_LAB)}>
              Lab1
            </IonButton>
          </>
        ) : (
          ''
        )}
      </IonContent>
    </IonPage>
  );
};

export default Settings;
