import { useEffect, useRef, useState } from 'react';
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import Avatar from 'react-avatar';
import { arrowBack, chevronForward, send } from 'ionicons/icons';
import moment from 'moment';
import * as _ from 'lodash';
import { sanitize } from 'dompurify';
import './Conversation.scss';
import { useChatsStore } from '../../../../stores/chats.store';
import { ChatEntry } from '../../../../models/conversation.model';
import { Chat } from '../../../../models/chat.model';
import { useTranslation } from 'react-i18next';
import {
  getChatName,
  isChatAnonymous,
  isUserInChatAlone,
} from '../../../../helpers/chat.helper';
import { URLS } from '../../../../routing/urls';
import { colors, defaultSanitizeOptions } from '../../../../utils/constants';
import { useUpdateLastRead } from '../../../../hooks/api/chat/useUpdateLastRead';
import { useFetchMessages } from '../../../../hooks/api/messages/useFetchMessages';
import { useSendMessage } from '../../../../hooks/api/messages/useSendMessage';
import { mapEventContentToMessage } from '../../../../mappers/mapEventContentToMessage';
import { App } from '@capacitor/app';

interface ConversationEntryProps {
  message: ChatEntry;
  myParticipantId: string;
}
function ConversationEntry({
  message,
  myParticipantId,
}: ConversationEntryProps) {
  const isSender = message.sender.id === myParticipantId;

  const sanitizeFn = (dirty, options = {}) => ({
    __html: sanitize(dirty, { ...defaultSanitizeOptions, ...options }),
  });

  const sanitizeHTML = ({ html, options }) => (
    <div
      className="chat-body"
      dangerouslySetInnerHTML={sanitizeFn(html, options)}
    />
  );

  return (
    <div className="chat-list">
      <div className={`chat-item ${isSender ? 'chat-item-sender' : ''}`}>
        <div className="chat-timestamp">
          {moment(message.createdAt).format('DD.MM.YYYY - HH:mm')}
        </div>

        <div className="chat-item-content">
          {!isSender && message.type !== 'event' ? (
            <div className="chat-avatar">
              <Avatar
                name={message.sender.name}
                // src={'https://joeschmoe.io/api/v1/' + chat.firstParticipants[1].participant.name}
                size="35px"
                round="30px"
                color={colors.primary}
                fgColor={colors.secondary}
              />
            </div>
          ) : null}

          {'message' in message.content ? (
            <div className="chat-item-bubble">
              {sanitizeHTML({
                html: message.content.message.content,
                options: {},
              })}
            </div>
          ) : null}
          {'event' in message.content ? (
            <div className="chat-item-event">
              {mapEventContentToMessage(message.content)}
            </div>
          ) : null}
        </div>
        {isSender ? (
          <div className="chat-item-status">
            <Avatar
              name={message.sender.name}
              size="15px"
              round="10px"
              color={colors.secondary}
            ></Avatar>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export function Conversation({ chatId }: any) {
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const { t } = useTranslation(['common', 'chatsPage']);
  const { updateLastRead } = useUpdateLastRead();
  const { fetchMessages } = useFetchMessages();
  const { sendMessageInChat } = useSendMessage();
  const chatEntries = useChatsStore((state) => state.chatEntries);
  const setChatEntries = useChatsStore((state) => state.setChatEntries);
  const chats = useChatsStore((state) => state.chats);
  const setChats = useChatsStore((state) => state.setChats);
  const setActiveChat = useChatsStore((state) => state.setActiveChat);

  const messages = chatEntries[chatId];

  const [newMessage, setNewMessage] = useState('');
  const [textInputSelected, setTextInputSelected] = useState(false);
  const chat: Chat = _.find(chats, { id: chatId })!;
  const isAnonymous = isChatAnonymous(chat);
  const chatName = getChatName(chat);

  const scrollToBottom = () => {
    contentRef.current?.scrollToBottom(500);
  };

  const fetchAllMessages = async () => {
    const newMessages = await fetchMessages(chatId);
    const newMessageList = newMessages.reverse();
    const oldMessages = useChatsStore.getState().chatEntries[chatId];

    if (!_.isEqual(oldMessages, newMessageList)) {
      setChatEntries(chatId, newMessages);
    }
  };

  useEffect(() => {
    console.log('setActiveChat [onMount]', chat.id);
    setActiveChat(chat);
    if (!messages) {
      fetchAllMessages();
    }
    return () => {
      console.log('setActiveChat [onDestroy] = null');
      setActiveChat(null);
    };
  }, []);

  useEffect(() => {
    if (messages) {
      scrollToBottom();
      handleLastRead();
    }
  }, [messages]);

  useEffect(() => {
    const resumeListener = App.addListener('resume', () => {
      if (isPlatform('cordova')) {
        fetchAllMessages();
      }
    });
    return () => {
      resumeListener.remove().then();
    };
  }, []);

  const sendMessage = () => {
    if (newMessage.trim() !== '') {
      sendMessageInChat(chatId, newMessage).then(() => {
        setNewMessage('');
      });
    }
  };

  const handleLastRead = () => {
    const notMyMessages = messages?.filter(
      (m) => m.sender.id !== chat.myContext.participantId,
    );

    if (!notMyMessages || !notMyMessages?.length) {
      return;
    }

    const lastMessageDate = notMyMessages[notMyMessages.length - 1].createdAt;

    updateLastRead(chatId, lastMessageDate).then(() => {
      if (chat.myContext.unread) {
        let draft = JSON.parse(JSON.stringify(chat));
        draft.myContext.unread = false;
        setChats(chats.map((ch) => (ch.id !== draft.id ? ch : draft)));
      }
    });
  };

  const handleInputFocus = () => {
    setTextInputSelected(true);
    handleLastRead();
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot={'start'}>
            <IonButton
              routerLink={URLS.APP_CHATS}
              routerDirection="back"
              color="secondary"
            >
              <IonIcon icon={arrowBack}></IonIcon>
            </IonButton>
            {!isUserInChatAlone(chat) && (
              <Avatar
                name={chatName}
                size="35px"
                round="30px"
                color={colors.primary}
                fgColor={colors.secondary}
              ></Avatar>
            )}
          </IonButtons>
          {isUserInChatAlone(chat) ? (
            <IonTitle color="danger">
              <h1 className="ion-no-margin">
                {t('youAloneInChat', { ns: 'chatsPage' })}
              </h1>
            </IonTitle>
          ) : (
            <h1 className="ion-no-margin ion-padding-start">
              {chat.type === 'help' && !isAnonymous && (
                <IonBadge color="primary" slot="end">
                  {t('helper')}
                </IonBadge>
              )}
              {chat.type === 'help' && isAnonymous && (
                <IonBadge color="secondary" slot="end">
                  {t('helper')} - {t('incognito')}
                </IonBadge>
              )}
              {chat.type === 'help' && <br />}
              {chatName}
            </h1>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef} className="ion-padding">
        {messages?.map((entry) => {
          return (
            <ConversationEntry
              key={entry.id}
              message={entry}
              myParticipantId={chat?.myContext.participantId}
            ></ConversationEntry>
          );
        })}
      </IonContent>
      <IonFooter class="ion-no-border">
        <IonToolbar>
          {!textInputSelected ? (
            <IonButtons slot="start">
              {/*<IonButton>*/}
              {/*  <IonIcon slot="icon-only" icon={addCircle} />*/}
              {/*</IonButton>*/}
              {/*<IonButton>*/}
              {/*  <IonIcon slot="icon-only" icon={camera} />*/}
              {/*</IonButton>*/}
              {/*<IonButton>*/}
              {/*  <IonIcon slot="icon-only" icon={image} />*/}
              {/*</IonButton>*/}
              {/*<IonButton>*/}
              {/*  <IonIcon slot="icon-only" icon={mic} />*/}
              {/*</IonButton>*/}
            </IonButtons>
          ) : (
            <IonButtons slot="start">
              <IonButton onClick={() => setTextInputSelected(false)}>
                <IonIcon slot="icon-only" icon={chevronForward}></IonIcon>
              </IonButton>
            </IonButtons>
          )}

          <IonTextarea
            className={'message-input'}
            placeholder="Aa"
            autoGrow={true}
            value={newMessage}
            autocapitalize="sentences"
            rows={1}
            onIonChange={(event: any) => setNewMessage(event.target.value)}
            onFocus={() => handleInputFocus()}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && !e.shiftKey) {
                sendMessage();
              }
            }}
          ></IonTextarea>

          <IonButtons slot="end">
            <IonButton onClick={sendMessage}>
              <IonIcon slot="icon-only" icon={send} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}
