import { Pusher } from '../../components/Pusher';
import { SecureRouting } from '../../routing/Secure.routing';
import { FC, useEffect } from 'react';
import { useAppSettingsStore } from '../../stores/appSettings.store';
import { AuthState, useAuthStore } from '../../stores/auth.store';
import { useTranslation } from 'react-i18next';
import { calculateReissueLimit } from '../../utils/jwt';
import moment from 'moment';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { AxiosInterceptor } from '../../logic/AxiosInterceptor';
import { SecureHandler } from '../../logic/SecureHandler';
import { useReissueAccessToken } from '../../hooks/api/auth/useReissueAccessToken';
import { PushNotification } from '../../components/PushNotification';
import { useAppSateStore } from '../../stores/appState.store';
import { useChatsStore } from '../../stores/chats.store';
import { has } from 'lodash';
import { isPlatform } from '@ionic/react';
import { Badge } from '@capawesome/capacitor-badge';

const Secure: FC = () => {
  const darkMode = useAppSettingsStore((state) => state.darkMode);
  const language = useAppSettingsStore((state) => state.language);
  const authState = useAuthStore((state) => state.authState);
  const appActiveState = useAppSateStore((state) => state.active);
  const unreadCount = useChatsStore((state) => state.unreadCount);
  const { i18n } = useTranslation();
  const { reissueAccessToken } = useReissueAccessToken();
  const accessToken = useAuthStore((state) => state.accessToken);
  const sessionToken = useAuthStore((state) => state.sessionToken);
  const setAccessToken = useAuthStore((state) => state.setAccessToken);
  const setSessionToken = useAuthStore((state) => state.setSessionToken);
  const setAuthState = useAuthStore((state) => state.setAuthState);

  const logout = () => {
    setAccessToken('');
    setSessionToken('');
    setAuthState(AuthState.loggedOut);
    window.location.replace('/');
  };

  const temporaryLogout = () => {
    setAccessToken('');
    setAuthState(AuthState.temporaryLoggedOut);
    // TODO: create lockscreen page, lockscreen flag in settings
    // window.location.replace('/lockscreen');
  };

  const isEligibleForReissueToken = (
    accessToken: string,
    sessionToken: string,
  ) => {
    const now = moment().unix();

    if (!sessionToken) {
      logout();
    }

    const sessionDecoded = jwt_decode(sessionToken) as JwtPayload;
    const accessDecoded = jwt_decode(accessToken) as JwtPayload;

    if (sessionDecoded && sessionDecoded.exp! <= now) {
      logout();
    }

    if (accessDecoded && accessDecoded.exp! <= now) {
      // TODO: finish temporaryLogout function. And use temporaryLogout() instead of logout()
      logout();
      // temporaryLogout()
    }

    const shouldReissueAfter = calculateReissueLimit(accessDecoded);

    return now > shouldReissueAfter;
  };

  const startInterval = () => {
    setInterval(() => {
      if (isEligibleForReissueToken(accessToken, sessionToken)) {
        reissueAccessToken().then((response) => {
          setAccessToken(response.accessToken);
          setSessionToken(response.sessionToken);
        });
      }
    }, 60000);
  };

  const setBadge = (count: number = 0) => {
    if (count === 0) {
      Badge.clear().then().catch();
    } else {
      Badge.set({ count }).then().catch();
    }
  };

  useEffect(() => {
    console.log('Load Secure content stuff....');
    if (authState !== AuthState.loggedIn) {
      window.location.replace('/');
    }
    startInterval();

    return () => {
      startInterval();
    };
  }, []);

  useEffect(() => {
    console.log('Selected language: ' + language);
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if (isPlatform('cordova') && appActiveState) {
      setBadge(unreadCount);
    }
  }, [unreadCount, appActiveState]);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [darkMode]);

  return (
    <Pusher>
      <PushNotification>
        <AxiosInterceptor>
          <SecureHandler>
            <SecureRouting></SecureRouting>
          </SecureHandler>
        </AxiosInterceptor>
      </PushNotification>
    </Pusher>
  );
};

export default Secure;
