import { useCallback } from 'react';
import { config } from '../../../config/config';
import { mbCoreApi } from '../api';

export const useSendMessage = () => {
  const func = useCallback(async (chatId: string, message: string) => {
    try {
      const response = await mbCoreApi.post(`${config.apiUrl}/v1/chats/${chatId}/messages`, {message});

      if (!response || !response.data) {
        return Promise.reject(new Error('useSendMessage: No data'));
      }

      return response.data;
    } catch (e: any) {
      throw new Error('useSendMessage:', e);
    }
  }, []);

  return {
    sendMessageInChat: func,
  };
};
