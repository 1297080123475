import { useCallback } from 'react';
import { config } from '../../../config/config';
import { Chat } from '../../../models/chat.model';
import { mbCoreApi } from '../api';

interface CreateChatPayload {
  accountIds: string[];
  name?: string;
  type?: 'help' | 'single';
  anonymous?: boolean;
}

export const useCreateChat = () => {
  const func = useCallback(async (payload: CreateChatPayload): Promise<Chat> => {
    try {
      const response = await mbCoreApi.post(`${config.apiUrl}/v1/chats`, payload);

      if (!response || !response.data) {
        return Promise.reject(new Error('useCreateChat: No data'));
      }

      return response.data;
    } catch (e: any) {
      throw new Error('useCreateChat:', e);
    }
  }, []);

  return {
    createChat: func,
  };
};
