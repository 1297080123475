import { useCallback } from 'react';
import { config } from '../../../config/config';
import { mbCoreApi } from '../api';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { useAuthStore } from '../../../stores/auth.store';

export const useReissueAccessToken = () => {
  const accessToken = useAuthStore(state => state.accessToken);
  const sessionToken = useAuthStore(state => state.sessionToken);

  const func = useCallback(async (): Promise<{accessToken: string, sessionToken: string}> => {
    if (!accessToken || !sessionToken) {
      throw new Error('useReissueAccessToken: No accessToken or no sessionToken!');
    }

    try {
      console.log('reissue AccessToken....');
      const decoded =  jwt_decode(sessionToken) as JwtPayload;

      const response = await mbCoreApi.put(
        `${config.apiUrl}/v1/sessions/${decoded.jti}/accessTokens`,
        { accessToken, sessionToken },
        { headers: { Authorization: `Bearer ${accessToken}` } },
      );

      if (!response || !response.data) {
        return Promise.reject(new Error('useReissueAccessToken: No data'));
      }

      return response.data;
    } catch (e: any) {
      throw new Error('useReissueAccessToken:', e);
    }
  }, []);

  return {
    reissueAccessToken: func,
  };
};
