import axios from "axios";
import {config} from "../../config/config";
import {AuthState, useAuthStore} from "../../stores/auth.store";

export const mbCoreApi = axios.create({
  baseURL: config.apiUrl,
});

export const createConfig = (accessToken: string) => ({
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  },
});


export const createConfigSync = () => {
  const accessToken = useAuthStore.getState().accessToken;
  const authState = useAuthStore.getState().authState;

  if (!accessToken || authState !== AuthState.loggedIn) {
    throw new Error('Not logged in');
  }

  return {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
};
