import { useEffect } from 'react';
import { URLS } from '../routing/urls';
import { useHistory } from 'react-router';
import { AuthState, useAuthStore } from '../stores/auth.store';
import { checkIsTokenEligible } from '../utils/jwt';
import { mbCoreApi } from '../hooks/api/api';

// https://codesandbox.io/s/axios-interceptor-react18-xgo3em?from-embed=&file=/src/axios.js
export const AxiosInterceptor = ({ children }) => {
  const history = useHistory();
  const accessToken = useAuthStore(state => state.accessToken);
  const setAccessToken = useAuthStore((state => state.setAccessToken))
  const setSessionToken = useAuthStore((state => state.setSessionToken))
  const setAuthState = useAuthStore((state => state.setAuthState))

  useEffect(() => {
    const logout = () => {
      setAccessToken('');
      setSessionToken('');
      setAuthState(AuthState.loggedOut);
    }

    const requestResInterceptor = (config) => {
      if (!accessToken) {
        logout();
        return Promise.reject();
      }

      if (!checkIsTokenEligible(accessToken)) {
        logout();
        return Promise.reject();
      }

      config.headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      }

      return config;
    };
    const requestInterceptor = mbCoreApi.interceptors.request.use(
      requestResInterceptor,
    )

    const resInterceptor = (response) => {
      return response;
    };
    const errInterceptor = (error) => {
      if (error.response.status === 401) {
        logout();
      }

      return Promise.reject();
    };
    const responseInterceptor = mbCoreApi.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => {
      mbCoreApi.interceptors.request.eject(requestInterceptor)
      mbCoreApi.interceptors.response.eject(responseInterceptor)
    };
  }, []);

  return children;
};
