import { useEffect, useState } from 'react';
import { useUserStore } from '../stores/user.store';
import { useFetchOrganization } from '../hooks/api/user/useFetchOrganization';
import { useFetchAccounts } from '../hooks/api/user/useFetchAccounts';
import {
  IonLoading,
  IonFab,
  IonFabButton,
  IonIcon,
} from '@ionic/react';
import { logOutOutline } from 'ionicons/icons';
import { AuthState, useAuthStore } from '../stores/auth.store';

const MAX_LOADING_TIME = 30 * 1000;
export const SecureHandler = ({ children }) => {
  const { fetchOrganization } = useFetchOrganization();
  const { fetchAccounts } = useFetchAccounts();

  const setAccounts = useUserStore((state) => state.setAccounts);
  const setOrganizations = useUserStore((state) => state.setOrganizations);
  const setAuthState = useAuthStore((state) => state.setAuthState);

  const [loading, setLoading] = useState(true);
  const [showButton, setShowButton] = useState(false);

  const logOutHandler = () => {
    setAuthState(AuthState.loggedOut);
  };
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      const accounts = await fetchAccounts();
      setAccounts(accounts);

      let orgDictionary = {};
      Promise.all(
        accounts.map(async (account) => {
          const org = await fetchOrganization(account.organizationId);
          orgDictionary[org.id] = org;
        }),
      ).then(() => {
        setOrganizations(orgDictionary);
        setLoading(false);
      });
    };

    fetchUserData().catch(console.error);
    setTimeout(() => {
      if (loading) {
        setShowButton(true);
      }
    }, MAX_LOADING_TIME);
  }, []);

  if (loading) {
    return (
      <>
        {showButton ? (
          <IonFab vertical="bottom" horizontal="end">
            <IonFabButton>
              <IonIcon icon={logOutOutline} onClick={logOutHandler}></IonIcon>
            </IonFabButton>
          </IonFab>
        ) : (
          ''
        )}
        <IonLoading
          isOpen={loading}
          duration={MAX_LOADING_TIME}
          message={'Fetching data...'}
        />
      </>
    );
  }

  return children;
};
