import { useCallback } from 'react';
import { useAuthStore } from '../../../stores/auth.store';
import { config } from '../../../config/config';
import { getUidFromJwt } from '../../../utils/jwt';
import { Account } from '../../../models/user.model';
import {createConfig, createConfigSync, mbCoreApi} from '../api';
import axios from "axios";
import {isPlatform} from "@ionic/react";
import { App } from '@capacitor/app';

interface AddPushDeviceRestBody {
  deviceToken: string;
  appIdentifier: string;
  apiLevel: number;
  platform?: 'ios' | 'android' | string;
  build: string;
  version: string;
}

async function addPushDeviceToken(deviceToken): Promise<boolean> {
  const accessToken = useAuthStore.getState().accessToken;
  const userId = getUidFromJwt(accessToken);
  const info = await App.getInfo();

  let platform = isPlatform('ios')
      ? 'ios-fcm'
      : isPlatform('android')
          ? 'android-fcm'
          : null;
  const build = info?.build ?? '1';
  const version = info?.version ?? '1';

  if (!platform) {
    console.log('Setting it default to ios for now');
    return false
    // throw new Error('Cannot determine the platform')
  }

  const restBody: AddPushDeviceRestBody = {
    deviceToken,
    appIdentifier: 'student',
    apiLevel: 4,
    build,
    version,
    platform,
  };

  const response = await axios.post(
      `${config.apiUrl}/v1/users/${userId}/deviceTokens`,
      restBody,
      createConfigSync(),
  );
  return response.data;
}

export const useAddPushToken = () => {
  const func = useCallback(async (deviceToken): Promise<boolean> => {
    try {
      const didDeleteToken = addPushDeviceToken(deviceToken);
      if (!didDeleteToken) {
        return Promise.reject(new Error('useAddPushToken: Could not add token'));
      }

      return true;
    } catch (e: any) {
      throw new Error('useAddPushToken:', e);
    }
  }, []);

  return {
    addPushToken: func,
  };
};
