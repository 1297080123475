import { OnParticipantAddedEvent, OnParticipantLeftEvent } from "../models/pusherEvent.model";
import { ChatEntry } from "../models/conversation.model";

export function mapPusherEventToChatEntry(data: OnParticipantAddedEvent | OnParticipantLeftEvent): ChatEntry {
  return {
    id: Date.now().toString(),
    chatId: data.event.chatId,
    content: {
      event: data.event,
    },
    type: 'event',
    sender: {
      anonymous: false,
      name: data.event.participants[0].name,
      id: data.event.participants[0].id,
    },
    edited: false,
    retracted: false,
    createdAt: new Date().toISOString(),
  };
}