import React, { FC, useEffect, useState } from 'react';
import { App } from '@capacitor/app';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import { usePushNotificationStore } from '../../../stores/pushNotification.store';
import { useRemovePushToken } from '../../../hooks/api/pushToken/useRemovePushToken';
import { useAuthStore } from '../../../stores/auth.store';

const LabPage: FC = (props: any) => {
  const addPushMessage = usePushNotificationStore(
    (state) => state.addPushMessage,
  );
  const deviceToken = usePushNotificationStore((state) => state.deviceToken);
  const pushMessages = usePushNotificationStore((state) => state.pushMessages);
  const setDeviceToken = usePushNotificationStore(
    (state) => state.setDeviceToken,
  );
  const { removePushToken } = useRemovePushToken();
  const sessionToken = useAuthStore((store) => store.sessionToken);
  const accessToken = useAuthStore((store) => store.accessToken);
  const [info, setInfo] = useState({});

  useEffect(() => {
    App.getInfo().then((info) => {
      setInfo(info);
    });
  }, []);
  const simulateAPushHandler = () => {
    addPushMessage({
      id: '123123',
      data: {},
      title: 'This is title',
      body: 'Notification text',
    });
  };
  const deleteTokenHandler = () => {
    const result = removePushToken(deviceToken);
    console.log(result);
    setDeviceToken('');
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>
            <h1 className="ion-no-margin">Welcome to the secret lab</h1>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>INFO: </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>{JSON.stringify(info)}</IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Auth: </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            AT: {accessToken}
            <br />
            ST: {sessionToken}
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>PUSH </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonLabel>Device token: </IonLabel>
            <IonInput value={deviceToken}></IonInput>

            <br/>
            <br/>
            {JSON.stringify(pushMessages)}
          </IonCardContent>
          <IonButton onClick={simulateAPushHandler}>Simulate a push</IonButton>
          <IonButton onClick={deleteTokenHandler}>Delete token</IonButton>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default LabPage;
