export const colors = {
  primary: '#fbc534',
  secondary: '#424242',
  tertiary: '#fff869',
  medium: '#92949c',
  light: '#f4f5f8'
}

export const defaultSanitizeOptions = {
  allowedTags: [ 'a', 'div', 'span', 'b', 'i', 'strong' ],
  allowedAttributes: {
    'a': [ 'href' ]
  },
  // allowedIframeHostnames: ['www.example.com'],
}
