import './secure.scss';
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet, IonText,
  IonToolbar
} from "@ionic/react";
import { Route } from 'react-router-dom';
import QaPage from '../pages/secure/qa/Qa.page';
import ChatsPage from '../pages/secure/chats/Chats.page';
import ConversationPage from '../pages/secure/chats/conversation/Conversation.page';
import HelperList from '../pages/secure/helpers/HelperList';
import Settings from '../pages/secure/settings/Settings';
import ContactsPage from '../pages/secure/contacts/Contacts.page';
import {
  chatbubble,
  cog,
  informationCircle,
  people,
} from 'ionicons/icons';
import { URLS } from './urls';
import { useChatsStore } from '../stores/chats.store';
import React, { FC, useState } from "react";
import { useTranslation } from 'react-i18next';
import PushPage from "../pages/secure/push/Push";
import InfoList from '../pages/secure/info/InfoList';
import LabPage from "../pages/secure/lab/Lab.page";

export const SecureRouting: FC  = () => {
  const { t } = useTranslation();
  const hasUnread = useChatsStore(state => state.hasUnread);
  const unreadCount = useChatsStore(state => state.unreadCount);
  const customTabList = [
    {
      id: 'chat',
      label: t("tabButtons.chat"),
      link: URLS.APP_CHATS,
      icon: chatbubble,
      selected: true,
      unread: false,
    },
    {
      id: 'helpers',
      label: t("tabButtons.helpers"),
      link: URLS.APP_HELPERS,
      icon: people,
      selected: false,
      unread: false,
    },
    {
      id: 'info',
      label: t("tabButtons.info"),
      link: URLS.APP_INFO,
      icon: informationCircle,
      selected: false,
      unread: false,
    },
    {
      id: 'settings',
      label: t("tabButtons.settings"),
      link: URLS.APP_SETTINGS,
      icon: cog,
      selected: false,
      unread: false,
    },
    // {
    //   id: 'qa',
    //   label: 'QA',
    //   link: URLS.APP_QA,
    //   icon: cog,
    //   selected: false,
    //   unread: false,
    // },
  ];
  const [tabs, setTabs] = useState(customTabList);

  const handleCustomTabClick = (tab) => {
    const index = customTabList.findIndex((t) => t.id === tab.id);
    if (index !== -1) {
      let draft = customTabList[index];
      draft.selected = true;
      setTabs(tabs.map(t => t.id !== draft.id ? { ...t, selected: false } : draft));
    }
  }

  console.log('---RERENDER---');

  return (
    <IonPage className="secure-routing-page">
      <IonContent>
        <IonRouterOutlet>
          <Route path={URLS.APP_QA} component={QaPage}/>
          <Route exact path={URLS.APP_CHATS} component={ChatsPage} />
          <Route exact path={URLS.APP_CONVERSATION} component={ConversationPage} />
          <Route path={URLS.APP_PUSH} component={PushPage} />
          <Route path={URLS.APP_CONTACTS} component={ContactsPage}/>
          <Route path={URLS.APP_HELPERS} component={HelperList}/>
          <Route path={URLS.APP_INFO} component={InfoList}/>
          <Route path={URLS.APP_SETTINGS} component={Settings}/>
          <Route path={URLS.APP_LAB} component={LabPage}/>
        </IonRouterOutlet>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons className="custom-tab-bar">
            {tabs.map((tab) => (
              <IonButton
                key={tab.id}
                routerLink={tab.link}
                routerOptions={{unmount: true}}
                className="custom-tab"
                onClick={() => {handleCustomTabClick(tab)}}
              >
                <div className="custom-tab-inner">
                  <IonIcon color={tab.selected ? 'primary' : 'medium'} icon={tab.icon} aria-label={tab.label} />
                  <IonLabel color={tab.selected ? 'other' : 'medium'}>{tab.label}</IonLabel>
                  {tab.id === 'chat' && hasUnread && <IonBadge color="danger" className="custom-tab__unread">
                      <IonText>{ unreadCount }</IonText>
                  </IonBadge>}
                </div>
              </IonButton>
            ))}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}
