import { useCallback } from 'react';
import { config } from '../../../config/config';
import { mbCoreApi } from '../api';
import { UpdateLastRead } from '../../../models/lastRead.model';

export const useUpdateLastRead = () => {
  const func = useCallback(async (chatId: string, lastRead: string): Promise<UpdateLastRead> => {
    try {
      const response = await mbCoreApi.post(`${ config.apiUrl }/v1/chats/${chatId}/lastRead`,{lastRead});

      if (!response || !response.data) {
        return Promise.reject(new Error('useUpdateLastRead: No data'));
      }

      return response.data;
    } catch (e: any) {
      throw new Error('useUpdateLastRead:', e);
    }
  }, []);

  return {
    updateLastRead: func,
  };
};
