import {env} from "./env";

const isWeb = process.env.REACT_APP_PLATFORM === 'web';

export const config = {
  environment: process.env.environment,
  isProd: env.environment === 'prod',
  loginUrl: process.env.REACT_APP_AUTH_URL ?? env.authUrl,
  apiUrl: process.env.REACT_APP_BASE_API ?? env.baseApi,
  isWeb,
  platform: (process.env.REACT_APP_PLATFORM ?? env.platform) as 'web' | 'app',
  pusher: {
    id: process.env.REACT_APP_PUSHER_ID ?? env.pusherId,
    encryptionMasterKeyBase64: process.env.REACT_APP_PUSHER_SECURE_ENCRYPTION_KEY ?? env.pusherSecureEncryptionKey,
    endpointUserAuth: process.env.REACT_APP_PUSHER_ENDPOINT_USER_AUTH ?? env.pusherEndpointUserAuth,
    endpointChannelAuth: process.env.REACT_APP_PUSHER_ENDPOINT_CHANNEL_AUTH ?? env.pusherEndpointChannelAuth
  },
};