import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
  RefresherEventDetail,
} from '@ionic/react';
import './Chats.page.css';
import { ChatList } from './ChatList';
import { useAuthStore } from '../../../stores/auth.store';
import { createOutline } from 'ionicons/icons';
import { FC, useEffect, useState } from 'react';
import { useChatsStore } from '../../../stores/chats.store';
import { useTranslation } from 'react-i18next';
import { useFetchChats } from '../../../hooks/api/chat/useFetchChats';
import { useHistory } from 'react-router';
import { useAppSateStore } from '../../../stores/appState.store';
import * as _ from 'lodash';

const ChatsPage: FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['common', 'chatsPage']);
  const { fetchChats } = useFetchChats();
  const chats = useChatsStore((state) => state.chats);
  const setChats = useChatsStore((state) => state.setChats);
  const resetChatEntries = useChatsStore((state) => state.resetChatEntries);
  const accessToken = useAuthStore((state) => state.accessToken);

  const appIsActive = useAppSateStore((state) => state.active);

  useEffect(() => {
    if (accessToken && appIsActive) {
      fetchChats().then((newChats) => {
        if (!_.isEqual(chats, newChats)) {
          setChats(newChats);
        }
      });
    }
  }, [appIsActive]);

  const goToContacts = () => {
    history.push('/app/contacts');
  };

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    fetchChats().then((data) => {
      setChats(data);
      resetChatEntries();
      event.detail.complete();
    });
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>
            <h1 className="ion-no-margin">{t('chats')}</h1>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="dark"
              onClick={goToContacts}
              aria-label="Go to contacts button"
            >
              <IonIcon
                slot="icon-only"
                icon={createOutline}
                aria-label="contacts icon"
              ></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {!chats?.length ? (
          <IonText className="ion-text-center">
            <h3>{t('youDontHaveChats', { ns: 'chatsPage' })}</h3>
          </IonText>
        ) : (
          <ChatList history={history} chats={chats} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default ChatsPage;
