import {
  IonButton,
  IonContent,
  IonHeader,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import { FC, useEffect, useRef, useState } from 'react';
import { Helper, HelperListGroup } from '../../../models/helper.model';
import { useChatsStore } from '../../../stores/chats.store';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import './HelperList.scss';
import { mapHelperListToHelperListGroup } from '../../../mappers/mapHelperListToHelperListGroup';
import { useCreateChat } from '../../../hooks/api/chat/useCreateChat';
import { useFetchHelpers } from '../../../hooks/api/helper/useFetchHelpers';

interface HelperEntryProps {
  helper: Helper;
}
const HelperEntry: FC<HelperEntryProps> = ({helper}) => {
  const { t } = useTranslation(['common', 'helpersPage']);
  const { createChat } = useCreateChat();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const chats = useChatsStore((state) => state.chats);
  const setChats = useChatsStore((state) => state.setChats);
  const modal = useRef<HTMLIonModalElement>(null);

  const createChatRoom = (anonymous: boolean) => {
    createChat({accountIds: [ helper.id ], anonymous, type: 'help'})
      .then((data) => {
        if (!chats?.some(c => c.id === data.id)) {
          let modifiedChats = chats ? [...chats] : [];
          modifiedChats.unshift(data);
          setChats(modifiedChats);
        }

        modal?.current?.dismiss().then();
        history.push(`/app/chats/${ data.id }`);
      });
  };

  const showHelperDetail = async () => {
    await modal?.current?.present();
  }

  const showConfirmCreateChat = async () => {
    await presentAlert({
      header: t('startChatWith', { ns: 'helpersPage', chatName: `${helper.firstName} ${helper.lastName}` }),
      subHeader: t('confirmAnonymChatDesc', { ns: 'helpersPage', orgName: `${helper.organizationName}` }),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'alert-button-cancel',
          handler: () => {
            modal?.current?.dismiss().then();
          }
        },
        {
          text: 'OK',
          role: 'confirm',
          cssClass: 'alert-button-confirm',
          handler: () => {
            createChatRoom(true);
          },
        },
      ],
      onDidDismiss: (e: CustomEvent) => {
        console.log('Dismissed', e.detail);
      },
    })
  }

  return (
    <IonItem
      key={helper.id}
      className="ion-margin"
      lines="full"
      detail
      onClick={showHelperDetail}
    >
      <IonLabel>
        <h3>{helper.firstName} {helper.lastName}</h3>
        <small>{helper?.helperInfo?.title}</small>
      </IonLabel>


      <IonModal
        ref={modal}
        trigger="open-modal"
        initialBreakpoint={0.75}
        breakpoints={[0, 0.25, 0.5, 0.75]}
        onWillDismiss={(e) => console.log('onWillDismiss_', e.detail)}
      >
        <IonContent className="ion-padding">
          <h2>{helper.firstName} {helper.lastName}</h2>
          <IonText color="medium">
            <small>{helper.organizationName}</small>
          </IonText>
          <p>{helper.helperInfo?.title}</p>
          <p>{helper.helperInfo?.description}</p>

          <IonButton color="primary" expand="block" onClick={() => createChatRoom(false)}>
            {t('startChat', {ns: 'helpersPage'})}
          </IonButton>
          <IonButton color="secondary" expand="block" className="ion-margin-top" onClick={showConfirmCreateChat}>
            {t('startAnonymChat', {ns: 'helpersPage'})}
          </IonButton>
        </IonContent>
      </IonModal>
    </IonItem>
  );
};


const HelperList: FC = () => {
  const { t } = useTranslation(['common', 'helpersPage']);
  const { fetchHelpers } = useFetchHelpers();
  const [helpers, setHelpers] = useState<Helper[]>([]);
  const [helperListGroup, setHelperListGroup] = useState<HelperListGroup[]>([]);

  useEffect(() => {
    fetchHelpers().then((helpers) => {
      setHelpers(helpers);
      setHelperListGroup(mapHelperListToHelperListGroup(helpers));
    });
  }, []);

  const handleSearch = (ev: Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();

    setHelperListGroup(mapHelperListToHelperListGroup(helpers, query));
  }

  return (
    <IonPage className="helper-list-page">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle><h1 className="ion-no-margin">{t('helpersPageTitle', {ns: 'helpersPage'})}</h1></IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar debounce={500} placeholder={t('findHelper', {ns: 'helpersPage'})} onIonChange={(ev) => handleSearch(ev)} />
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {!helpers.length && (
          <IonText color="medium">
            <p className="ion-padding">{t('noHelpers', {ns: 'helpersPage'})}</p>
          </IonText>
        )}
        <IonList>
          {helperListGroup.map((group, index) => (
            <IonItemGroup key={index} role="listitem">
              <IonItemDivider>
                <IonLabel><h2>{group.organizationName}</h2></IonLabel>
              </IonItemDivider>
              { group.entries.map((helper) => (
                <div role="list" key={helper.id}>
                  <HelperEntry helper={ helper }/>
                </div>
              )) }
            </IonItemGroup>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default HelperList;
