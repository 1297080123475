import { LogoutListener } from './logout.listener';
import { StartListener } from './start.listener';
import {AppStateListener} from "./app.listener";

export function Listeners({ children }) {
  return (
    <StartListener>
      <AppStateListener>
        <LogoutListener>{children}</LogoutListener>
      </AppStateListener>
    </StartListener>
  );
}
