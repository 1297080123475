import { useCallback } from 'react';
import { config } from '../../../config/config';
import { mbCoreApi } from '../api';

export const useLeaveChat = () => {
  const func = useCallback(async (chatId: string, participantId: string) => {
    try {
      const response = await mbCoreApi.delete(`${config.apiUrl}/v1/chats/${chatId}/participants/${participantId}`);

      if (!response || !response.data) {
        return Promise.reject(new Error('useLeaveChat: No data'));
      }

      return response.data;
    } catch (e: any) {
      throw new Error('useLeaveChat:', e);
    }
  }, []);

  return {
    leaveChat: func,
  };
};
