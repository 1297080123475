import { IonAvatar, IonItem, IonLabel, IonList } from '@ionic/react';
import Avatar from 'react-avatar';
// import './ChatList.scss'
import { Contact } from '../../../models/contact.model';
import { useChatsStore } from '../../../stores/chats.store';
import { useCreateChat } from '../../../hooks/api/chat/useCreateChat';

interface ContactProps {
  contact: Contact;
  history: any;
}
function ContactEntry({ contact, history }: ContactProps) {
  const { createChat } = useCreateChat();
  const name = `${contact.firstName} ${contact.lastName}`;
  const chats = useChatsStore((state) => state.chats);
  const setChats = useChatsStore((state) => state.setChats);

  const createChatRoom = () => {
    createChat({accountIds: [contact.id]}).then((data) => {
      if (!chats?.some(c => c.id === data.id)) {
        let modifiedChats = chats ? [...chats] : [];
        modifiedChats.unshift(data);
        setChats(modifiedChats);
      }

      history.push(`/app/chats/${ data.id }`);
    })
  }

  return (
      <IonItem lines="full" className="contact-item" detail={true} onClick={createChatRoom}>
        <IonAvatar slot="start">
          <Avatar
            name={name}
            size="40px"
            round="40px"
          />
        </IonAvatar>
        <IonLabel>
          <h2 className="contact-name">{name}</h2>
          <div className="contact-description">
            {contact.organizationName}
          </div>
        </IonLabel>
      </IonItem>
  );
}

interface ContactListProps {
  contacts: Contact[];
  history: any;
}
export function ContactList({history, contacts}: ContactListProps) {
  return (
    <div className="contact-list">
      <IonList>
        {contacts.map((contact) => (
          <ContactEntry
            key={contact.id}
            contact={contact}
            history={history}
          ></ContactEntry>
        ))}
      </IonList>
    </div>
  );
}
