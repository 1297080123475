import {
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonRouter,
} from '@ionic/react';
import '../../secure/qa/Qa.page.css';
import { useEffect} from 'react';
import axios from 'axios';

// eslint-disable-next-line no-restricted-globals
import {AuthState, useAuthStore} from "../../../stores/auth.store";
import {config} from "../../../config/config";
import { URLS } from '../../../routing/urls';

export const Auth = (props) => {
  const setAccessToken = useAuthStore((state => state.setAccessToken))
  const setSessionToken = useAuthStore((state => state.setSessionToken))
  const setAuthState = useAuthStore((state => state.setAuthState))
  const router = useIonRouter();

  useEffect(() =>{
    const params = new URLSearchParams(window.location.search);
    const tgt = params.get('tgt') ?? null;
    if (tgt) {
      axios.post(`${config.apiUrl}/v1/sessions`, {
        tgt,
      }).then((response) => {
        setAccessToken(response.data.accessToken);
        setSessionToken(response.data.sessionToken);
        setAuthState(AuthState.loggedIn);
        if (isPlatform('cordova')) {
          console.log({routeInfo: router.routeInfo});
          setTimeout(() => {
            router.push(URLS.APP_CHATS, "root", "replace")
          }, 1)
        } else {
          window.location.replace(URLS.APP_CHATS);
        }
      }).catch((e) => {
        console.log(e.message);
      });
    }
  }, [])
  return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle></IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonLoading
              cssClass="my-custom-class"
              isOpen={true}
              message={'Please wait...'}
              duration={5000}
          />
        </IonContent>
      </IonPage>
  );
}

