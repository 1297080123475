import { useEffect } from 'react';
import { AuthState, useAuthStore } from '../stores/auth.store';
import { PushNotifications } from '@capacitor/push-notifications';
import { usePushNotificationStore } from '../stores/pushNotification.store';
import { isPlatform, useIonToast } from '@ionic/react';
import * as _ from 'lodash';
import {useAddPushToken} from "../hooks/api/pushToken/useAddPushToken";

const registerNotifications = async () => {

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }


  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }
  PushNotifications.register().then(() => {
  }).catch(() => {});
};

export function PushNotification({ children }) {
  const authState = useAuthStore((state) => state.authState);
  const addPushMessage = usePushNotificationStore(
    (state) => state.addPushMessage,
  );
  const [present] = useIonToast();
  const setDeviceToken = usePushNotificationStore(
    (state) => state.setDeviceToken,
  );

  const {addPushToken} = useAddPushToken()
  useEffect(() => {

    const unsubscribe = usePushNotificationStore.subscribe((state) => {
      const msg = _.last(state.pushMessages);
      if (msg) {
        console.log("Push message received:");
        console.log({
          msg
        });

        // present({
        //   message: JSON.stringify(msg),
        //   duration: 2000,
        //   position: 'bottom',
        // });
      }
      return unsubscribe;
    });
  }, []);

  useEffect(() => {
    if (
      PushNotifications &&
      authState === AuthState.loggedIn &&
      isPlatform('cordova')
    ) {

      PushNotifications.addListener('registration', (token) => {
        console.log('Registration token: ' + token.value);

        const deviceToken = token.value;
        addPushToken(deviceToken).then(() => {
          setDeviceToken(deviceToken);
          // present({
          //   message: 'Devicetoken found: ' + deviceToken,
          //   duration: 1000,
          //   position: 'bottom',
          // });
        });

      }).catch((e) => {});

      PushNotifications.addListener('registrationError', (err) => {

        present({
          message: 'Registration error: ' + err.error,
          duration: 1000,
          position: 'bottom',
        });
      });

      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          console.log(
            'Push notification received: ' + notification.title + notification.body,
          );
          addPushMessage(notification);

        },
      ).catch((e) => {});

      // PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      //   console.log('Push notification action performed' + notification.actionId + ' ' + JSON.stringify(notification.inputValue));
      // }).catch((e) =>{
      //   console.log('Error (pushNotificationActionPerformed):' + e.message())
      // });
    } else {
      console.log('PushNotifications object is not present... (none mobile)');
      setDeviceToken('WEB - No token');
    }
  }, []);

  useEffect(() => {
    registerNotifications().catch(() => {});
  }, []);

  return <>{children}</>;
}
