import { useCallback } from 'react';
import { Organization } from '../../../models/user.model';
import { config } from '../../../config/config';
import { mbCoreApi } from '../api';

export const useFetchOrganization = () => {
  const func = useCallback(async (orgId: string): Promise<Organization> => {
    try {
      console.log('fetching organization....');

      const response = await mbCoreApi.get(`${config.apiUrl}/v1/organizations/${orgId}`);

      if (!response || !response.data) {
        return Promise.reject(new Error('useFetchOrganization: No data'));
      }

      return response.data;
    } catch (e: any) {
      throw new Error('useFetchOrganization:', e);
    }
  }, []);

  return {
    fetchOrganization: func,
  };
};
