import {
  IonAvatar,
  IonBadge,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList, IonText, useIonAlert,
} from '@ionic/react';
import Avatar from 'react-avatar';
import './ChatList.scss'
import moment from "moment/moment";
import { useTranslation } from 'react-i18next';
import { useChatsStore } from '../../../stores/chats.store';
import { getChatName, isChatAnonymous, isUserInChatAlone } from '../../../helpers/chat.helper';
import { ellipse } from 'ionicons/icons';
import { colors } from '../../../utils/constants';
import { useLeaveChat } from '../../../hooks/api/chat/useLeaveChat';

function ChatEntry({ chat, history }: any) {
  const chats = useChatsStore((state) => state.chats);
  const setChats = useChatsStore((state) => state.setChats);
  const setChatEntries = useChatsStore(state => state.setChatEntries);
  const { t } = useTranslation(['common', 'chatsPage']);
  const { leaveChat } = useLeaveChat();
  const [presentAlert] = useIonAlert();
  const displayDate = (dateObj) =>{
    const startOfDay = moment().startOf('day');
    const date = moment(dateObj);

    if (startOfDay.isBefore(date)){
      return date.format('HH:mm')
    } else if (startOfDay.subtract(5, 'day').isBefore(date)) {
      return date.format('ddd')
    }
    return date.format('D.MMM')
  };

  const isAnonymous = isChatAnonymous(chat);
  const chatName = getChatName(chat);

  let textPreview = chat.lastMessage?.message.content.message.content ?? null;
  if (textPreview && chat.lastMessage.sender.id === chat.myContext.participantId) {
    textPreview = 'Deg: ' + textPreview
  }

  const showConfirmLeaveChat = async () => {
    await presentAlert({
      header: t('confirmLeaveChat', { ns: 'chatsPage', chatName: chatName }),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: async () => {
            try {
              await leaveChat(chat.id, chat.myContext.participantId);
              setChatEntries(chat.id, []);
              setChats(chats.filter(c => c.id !== chat.id));
            } catch (e) {
              throw new Error('Leave chat is failed');
            }
          },
        },
      ],
      onDidDismiss: (e: CustomEvent) => {
        console.log('Dismissed', e.detail);
      },
    })
  }
  const navigateToConversation = () => {
    history.push('chats/' + chat.id);
  }

  return (
    <IonItemSliding>
      <IonItem lines="full" className={chat.myContext.unread ? 'chat-item unread' : 'chat-item'} onClick={navigateToConversation}>
        <IonAvatar slot="start" className={`${chat.participantCount > 2? 'avatar-stacked' : ''}`}>
          <Avatar
            name={chat.name || chatName}
            size="40px"
            round="40px"
            color={colors.primary}
            fgColor={colors.secondary}
          />
          {chat.participantCount > 2?
            <Avatar
              name={chat.firstParticipants[0]?.participant.name ?? ''}
              size="40px"
              round="40px"
              color={colors.primary}
              fgColor={colors.secondary}
            /> : ''}

        </IonAvatar>
        <IonLabel>
          {(chat.type === 'help' && !isAnonymous) && <IonBadge color="primary">{t('helper')}</IonBadge>}
          {(chat.type === 'help' && isAnonymous) && <IonBadge color="secondary">{t('helper')} - {t('incognito')}</IonBadge>}
          {isUserInChatAlone(chat) ?
            <IonText color="danger"><h2 className="chat-name">{t('youAloneInChat', { ns: 'chatsPage'})}</h2></IonText>
            : <h2 className="chat-name">{chat.name || chatName}</h2>
          }
          <div className="chat-description">
            <div className="content-preview">{textPreview}</div>
            <div className="time-ago">· {displayDate(chat.lastMessage?.message.created_at)}</div>
          </div>
        </IonLabel>
        {chat.myContext.unread && <IonIcon size="small" icon={ellipse} color="primary" aria-label="Unread icon"></IonIcon>}
      </IonItem>

      <IonItemOptions>
        <IonItemOption color="danger" onClick={showConfirmLeaveChat}>{t('leave')}</IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
}

export function ChatList({history, chats}: any) {
  return (
    <div className="chat-list" >
      <IonList>
        {chats.map((chat) => (
          <ChatEntry
            key={chat.id}
            chat={chat}
            history={history}
          ></ChatEntry>
        ))}
      </IonList>
    </div>
  );
}
