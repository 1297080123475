export const URLS = {
  APP: '/app',
  APP_QA: '/app/qa',
  APP_CHATS: '/app/chats',
  APP_CONVERSATION: '/app/chats/:id',
  APP_CONTACTS: '/app/contacts',
  APP_HELPERS: '/app/helpers',
  APP_SETTINGS: '/app/settings',
  APP_PUSH: '/app/push',
  APP_INFO: '/app/info',
  APP_LAB: '/app/lab',
  // open routes
  LOGIN: '/login',
  SIGNUP: '/signup',
  AUTH_CALLBACK: '/auth/callback'
};
