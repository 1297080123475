const configurator = require('json-configurator');
const stage = process.env.STAGE || 'development';

const environment =  {
  environment: 'qa',
  authUrl: 'https://t-mb-id-qa.herokuapp.com/auth?callback=aHR0cHM6Ly90LXN0dWRlbnQtcWEuaGVyb2t1YXBwLmNvbS9hdXRoL2FwcENhbGxiYWNr',
  baseApi: 'https://t-mb-core-qa.herokuapp.com',
  pusherSecureEncryptionKey: 'kkGH4lZ1V9NnQyEUIlkpPheSweEvEZNJ2SXNilsu40M=',
  pusherEndpointChannelAuth: 'https://t-mb-core-qa.herokuapp.com/v1/pusher/auth',
  pusherEndpointUserAuth: 'https://t-mb-core-qa.herokuapp.com/v1/pusher/user-auth',
  pusherId: 'feba551990427fee6209',

  $dev_environment: 'dev',
  $dev_authUrl: 'https://t-mb-id-qa.herokuapp.com/auth?callback=aHR0cDovL2xvY2FsaG9zdDo5MDAwL2F1dGgvY2FsbGJhY2s=',

  $stag_environment: 'staging',
  $stag_authUrl: 'https://t-mb-id-staging.herokuapp.com/auth?callback=aHR0cHM6Ly90LXN0dWRlbnQtc3RhZ2luZy5oZXJva3VhcHAuY29tL2F1dGgvYXBwQ2FsbGJhY2s=',
  $stag_baseApi: 'https://t-mb-core-staging.herokuapp.com',
  $stag_pusherSecureEncryptionKey: '/Ifuax6vfAhqTsVQ0gWEA3NSmHTXFnhRN3rSCrc7lek=',
  $stag_pusherEndpointChannelAuth: 'https://t-mb-core-staging.herokuapp.com/v1/pusher/auth',
  $stag_pusherEndpointUserAuth: 'https://t-mb-core-staging.herokuapp.com/v1/pusher/user-auth',
  $stag_pusherId: '55aaa7fb466201ae9405',

  $p_environment: 'prod',
  $p_authUrl:
      'https://id.transponder.no/auth?callback=aHR0cHM6Ly9zdHVkZW50LnRyYW5zcG9uZGVyLm5vL2F1dGgvYXBwQ2FsbGJhY2s=',
  $p_baseApi: 'https://api.transponder.no',
  $p_pusherSecureEncryptionKey: '4nkbt1RWNrNd+SdVtkZd+L/+ag5vP/zDMPXllQecRKM=',
  $p_pusherEndpointChannelAuth: 'https://api.transponder.no/v1/pusher/auth',
  $p_pusherEndpointUserAuth: 'https://api.transponder.no/v1/pusher/user-auth',
  $p_pusherId: 'bf04d4a161a543e7eebf',
};


const envMapping = {
  development: 'dev',
  qa: 'qa',
  prod: 'p',
  staging: 'stage',
};

// @ts-ignore
export let env = configurator(environment, envMapping[stage]);