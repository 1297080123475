import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { subscribeWithSelector } from 'zustand/middleware'
import chatsPage from "../pages/secure/chats/Chats.page";

// THIS store is only lives within a session (starts from first request when you are logged in to device off or close browser or temporarily logged out (pin code))
interface EventPusherState {
  lastEvent: {name: string, data: any}
  events: {[key : string]: any[]};
  setLastEvent: (eventName: string, obj: any) => void;
  addEvent: (eventName: string, obj: any) => void;
}
const eventPusherStore = (set) => ({
  lastEvent: {},
  events: {},
  setLastEvent: (eventName:string, eventObj: any) => set({lastEvent: {name: eventName, data:eventObj}}, null, "eventPusherStore.setLastEvent"),
  addEvent: (eventName:string, eventObj: any) => set((state) => {
    if (!state.events[eventName]) {
      state.events[eventName] = [];
    }
    state.events[eventName].push(eventObj)
    return state
  }, false, "eventPusherStore.addEvent"),
});

export const useEventPusherStore = create<EventPusherState>()(
// @ts-ignore
  devtools(subscribeWithSelector<EventPusherState>(eventPusherStore)
    , {
    name: 'eventPusherState',
  }),
);
