import {
  IonButton,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonInput,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { PushNotifications } from '@capacitor/push-notifications';
import React, {useEffect, useState} from "react";

const PushPage: React.FC = () => {



  const [logs, setLogs] = useState([''])
  const [token, setToken] = useState('')

  const log = (logLine) => {
    setLogs([...logs, `${logs.length}: ${logLine}`]);
  }

  const registerNotifications = async () => {
    log('registerNotifications....')
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
    PushNotifications.register().then(() => {
      console.log('DONE registrering');
    }).catch(() => {
      console.log(10987);

    });
  }

  useEffect(() => {
    if (PushNotifications) {
    log("Adding listeners...")

      PushNotifications.addListener('registration', token => {
        log('Registration token: '+ token.value);
        setToken((token.value))

      }).catch((e) =>{
        log('Error (registration):' + e.message())
      })

      PushNotifications.addListener('registrationError', err => {
        log('Registration error: ' + err.error);
      }).catch((e) =>{
        log('Error (registrationError):' + e.message())
      })

      PushNotifications.addListener('pushNotificationReceived', notification => {
        log('Push notification received:' + JSON.stringify(notification));
      }).catch((e) =>{
        log('Error (pushNotificationReceived):' + e.message())
      });

      PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        log('Push notification action performed' + notification.actionId + ' ' + JSON.stringify(notification.inputValue));
      }).catch((e) =>{
        log('Error (pushNotificationActionPerformed):' + e.message())
      });

    } else {
      log("PushNotifications object is not present... 😭")
    }
  }, []);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle><h1 className="ion-no-margin">Push test page</h1></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonItem>
          <IonLabel>Token</IonLabel>
          <IonInput value={token}></IonInput>
        </IonItem>

        <IonButton onClick={registerNotifications}>Register token</IonButton>
        <br/>
        LOG:
        {/*<pre>*/}
        {/*  {logs.join('\n')}*/}
        {/*</pre>*/}
        {logs.map((log =>
        <div key={log.toString()}>{log}</div>
        ))}

      </IonContent>
    </IonPage>
  );
};

export default PushPage;
