import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// THIS store is only lives within a session
interface PushNotificationState {
  pushMessages: any;
  addPushMessage: (msg: any) => void;
  deviceToken: string;
  setDeviceToken: (token: string) => void;
}
const pushMessageStore = (set, get) => ({
  pushMessages: [],
  deviceToken: "",
  addPushMessage: (msg) => set({ pushMessages: [...get().pushMessages, msg] }),
  setDeviceToken: (deviceToken)=> set({deviceToken})
});

export const usePushNotificationStore = create<PushNotificationState>()(
  devtools(pushMessageStore, {
    name: 'PushNotificationState',
  }),
);
