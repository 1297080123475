import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import './Qa.page.css';
import { Trans, useTranslation } from 'react-i18next';

const QaPage: React.FC = () => {
  const { t } = useTranslation('settingsPage');

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Jeg lurer på.....</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Jeg lurer på.....</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonAccordionGroup>
          <IonAccordion value="first">
            <IonItem slot="header" color="light">
              <IonLabel>In semper ex nec lorem bibendum </IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              Sed euismod tincidunt egestas. Integer interdum purus mi, ac
              imperdiet eros accumsan accumsan. Pellentesque habitant morbi
              tristique senectus et netus et malesuada fames ac turpis egestas.
            </div>
          </IonAccordion>
          <IonAccordion value="second">
            <IonItem slot="header" color="light">
              <IonLabel>Aliquam ut fermentum lectus</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              Fusce vitae pretium urna, eget sollicitudin ligula. Aliquam
              condimentum ligula vel quam vehicula luctus. Vestibulum ornare sit
              amet dui nec ullamcorper. Fusce sit amet magna id sapien semper
              semper vel lobortis lorem. Cras ut metus ut nibh elementum
              consequat. Proin sed lacinia orci. Maecenas ultricies imperdiet
              velit, nec porttitor lorem commodo non. Sed tempor risus id
              pretium euismod. Cras at enim ac ex faucibus rutrum et sed magna.
            </div>
          </IonAccordion>
          <IonAccordion value="third">
            <IonItem slot="header" color="light">
              <IonLabel>Nullam sodales finibus lacinia</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              Suspendisse congue congue sapien eget interdum. Mauris ultricies,
              lectus eu venenatis dignissim, risus dui scelerisque nunc, at
              convallis nisi libero quis elit. Donec ut laoreet arcu. Integer
              ultrices interdum luctus. Curabitur tincidunt orci sit amet
              venenatis sodales. Sed luctus malesuada dolor vel elementum. Nunc
              blandit nulla nunc, et aliquet arcu consequat eget. Cras in
              commodo sapien.
            </div>
          </IonAccordion>
        </IonAccordionGroup>

        <p>{t('welcome')}</p>
        <Trans i18nKey={'lineTrans'}>
          This is <a> a test</a> with multiple <code>tags</code>!
        </Trans>

        <p>{t('test')}</p>
        <p>{t('hobby', { hobby: 'C++' })}</p>
        <p>{t('car', { count: 0 })}</p>
        <p>{t('car', { count: 1 })}</p>
        <p>{t('car', { count: 2 })}</p>
        <p>{t('yes')}</p>
        <p>{t('new.key')}</p>
        <p>{t('new.key1')}</p>
        <p>{t('cake')}</p>
      </IonContent>
    </IonPage>
  );
};

export default QaPage;
