import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Chat } from '../models/chat.model';
import { ChatEntry } from '../models/conversation.model';
import * as _ from 'lodash';

const REDUX_ACTION_PREFIX = 'chatsStore.';
// THIS store is only lives within a session (starts from first request when you are logged in to device off or close browser or temporarily logged out (pin code))
interface ChatsState {
  chats: Chat[];
  setChats: (chats: any[]) => void;
  chatEntries: { [key: string]: ChatEntry[] };
  setChatEntries: (chatId: string, chatEntries: ChatEntry[]) => void;
  resetChatEntries: () => void;
  activeChat: Chat | null;
  setActiveChat: (chat: Chat | null) => void;
  hasUnread: boolean;
  unreadCount: number;
}
const chatsStore = (set, get) => ({
  chats: null,
  setChats: (chats: any[]) => {
    set({ chats }, false, REDUX_ACTION_PREFIX + 'setChats');
    const unreadCount = _.filter(chats, 'myContext.unread').length;
    set({
      unreadCount: unreadCount,
      hasUnread: unreadCount > 0,
    });
  },
  activeChat: null,
  setActiveChat: (activeChat: Chat | null) =>
    set({ activeChat }, false, REDUX_ACTION_PREFIX + 'setActiveChat'),

  chatEntries: {},
  setChatEntries: (chatId: string, chatEntries: ChatEntry[]) => {
    set(
      { chatEntries: { ...get().chatEntries, [chatId]: chatEntries } },
      false,
      REDUX_ACTION_PREFIX + 'setChatEntries',
    );
  },
  resetChatEntries: () => {
    set({ chatEntries: {} }, false, REDUX_ACTION_PREFIX + 'resetChatEntries');
  },
  hasUnread: false,
  unreadCount: 0,
});

export const useChatsStore = create<ChatsState>()(
  // @ts-ignore
  devtools(chatsStore, {
    name: 'chatsState',
  }),
);
