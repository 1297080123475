import _ from 'lodash';
import { CategoryVM, InfoPage, InfoPageViewModel } from '../models/info.model';

const mapPagesByCategory = (pages: InfoPage[]): CategoryVM[] => {
  let uniqCategories = _.uniq(pages.map((p) => p.category)).sort((a, b) =>
    a.localeCompare(b),
  );

  return uniqCategories.map((category) => ({
    categoryName: category,
    infoPages: pages.filter((p) => p.category === category),
  }));
};

export const mapInfoPageListToInfoPageVMList = (
  pages: InfoPage[],
  searchKey?: string,
): InfoPageViewModel[] => {
  if (!pages?.length) {
    return [];
  }
  const mappedOrganizations = pages.filter((p) => p.organizationId).map((p) => ({
    id: p.organizationId!,
    name: p.organizationName!
  }))
  const mappedFederations = pages.filter((p) => p.federationId).map((p) => ({
    id: p.federationId!,
    name: p.federationName!
  }))

  const uniqueOrganizations = _.uniqBy(mappedOrganizations, 'id');
  const uniqueFederations = _.uniqBy(mappedFederations, 'id');

  const filteredPages = pages
    .filter(
      (p) =>
        p.category.toLowerCase().indexOf(searchKey?.toLowerCase() ?? "") !== -1
        || p.title.toLowerCase().indexOf(searchKey?.toLowerCase() ?? "") !== -1
        || p.content.body.toLowerCase().indexOf(searchKey?.toLowerCase() ?? "") !== -1
    )
    .sort((a, b) => `${a.category}`.localeCompare(`${b.category}`));

  const infoPageOrganizationEntries: InfoPageViewModel[] = uniqueOrganizations.map((org) => {
    const categories = mapPagesByCategory(_.filter(filteredPages, {organizationId: org.id}))
    if (categories.length === 0) {
      return null;
    }
    return {
      organizationName: org.name,
      categories,
    };
  }).filter(Boolean) as InfoPageViewModel[];

  const infoPageFederationEntries: InfoPageViewModel[] = uniqueFederations.map(
    (fed) => {
      const categories = mapPagesByCategory(_.filter(filteredPages,{federationId: fed.id}))
      if (categories.length === 0) {
        return null;
      }
      return {
        federationName: fed.name,
        categories
      };
    },
  ).filter(Boolean) as InfoPageViewModel[];
  const sortedInfoOrgPages = _.sortBy(infoPageOrganizationEntries, 'organizationName');
  const sortedInfoFedPages = _.sortBy(infoPageFederationEntries, 'federationName');
  return [...sortedInfoOrgPages, ...sortedInfoFedPages];
};
