import { useEffect } from 'react';
import { useEventPusherStore } from '../stores/pusher.store';
import { useChatsStore } from '../stores/chats.store';
import { Chat } from '../models/chat.model';
import * as _ from 'lodash';
import produce from 'immer';
import { ChatEntry } from '../models/conversation.model';
import { OnChatUpdatedEvent, OnMessageCreatedEvent } from "../models/pusherEvent.model";
import { mapPusherEventToChatEntry } from "../mappers/mapPusherEventToChatEntry";
import { mapChatUpdatedEventToChat } from "../mappers/mapChatUpdatedEventToChat";

export function PusherHandler({ children }) {
  const setChats = useChatsStore(state => state.setChats);

  useEffect(() => {
    const unsubscribe = useEventPusherStore.subscribe(
      (state) => state.lastEvent,
      (event) => {
        const chats = useChatsStore.getState().chats;
        const activeChat = useChatsStore.getState().activeChat;
        const chatEntries = useChatsStore.getState().chatEntries;

        console.log('Handling event: ' + event.name);
        console.log('Event data: ', event.data);

        switch (event.name) {
          case 'message-created': {

            if (!activeChat || activeChat?.id !== event.data.chatId) {
              const index = chats.findIndex((ch) => ch.id === event.data.chatId);
              if (index !== -1) {
                let draft = JSON.parse(JSON.stringify(chats[index]));
                draft.myContext.unread = true;
                setChats(chats.map(ch => ch.id !== draft.id ? ch : draft));
              }
            }

            const newChats = onMessageCreated(
              useChatsStore.getState().chats,
              event.data as OnMessageCreatedEvent,
            );
            useChatsStore.setState({ chats: newChats });

            if (chatEntries[event.data.chatId]) {
              const newChatsEntries = onMessageCreateInConversation(
                chatEntries[event.data.chatId],
                event.data as OnMessageCreatedEvent,
              );
              useChatsStore.setState({
                chatEntries: {
                  ...chatEntries,
                  [event.data.chatId]: newChatsEntries,
                },
              });
            }

            break;
          }
          case 'chat-updated': {
            const newChats = onChatUpdated(
              useChatsStore.getState().chats,
              event.data as OnChatUpdatedEvent,
            );
            useChatsStore.setState({ chats: newChats });
            break;
          }
          case 'chat-created': {
            const newChats = onChatCreated(
              useChatsStore.getState().chats,
              event.data as OnChatUpdatedEvent,
            );
            useChatsStore.setState({ chats: newChats });
            break;
          }
          case 'participant-left': {
            const newChatEntry = mapPusherEventToChatEntry(event.data);
            const chatMessages = chatEntries[event.data.event.chatId];
            if (chatMessages) {
              useChatsStore.setState({
                chatEntries: {
                  ...chatEntries,
                  [event.data.event.chatId]: [...chatMessages, newChatEntry],
                },
              });
            }
            break;
          }
          case 'participant-added': {
            const newChatEntry = mapPusherEventToChatEntry(event.data);
            const chatMessages = chatEntries[event.data.event.chatId];
            if (chatMessages) {
              useChatsStore.setState({
                chatEntries: {
                  ...chatEntries,
                  [event.data.event.chatId]: [...chatMessages, newChatEntry],
                },
              });
            }
            break;
          }
          default: {
            console.log('REMEMBER TO HANDLE: ' + event.name);
          }
        }
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return <>{children}</>;
}

function onMessageCreated(
  currentState: Chat[],
  event: OnMessageCreatedEvent,
): Chat[] {
  const nextState = produce(currentState, (draft) => {
    const chat = _.find(draft, { id: event.chatId });

    if (!chat) {
      return;
    }

    if (chat.lastMessage) {
      if (chat.lastMessage.sender) {
        chat.lastMessage.sender = event.message.sender;
      }
      chat.lastMessage.message.content.message.content = event.message.content;
      chat.lastMessage.message.created_at = event.message.createdAt;
    }

    if (!chat.lastMessage) {
      chat.lastMessage = {
        sender: event.message.sender,
        message: {
          id: event.message.id,
          edited: event.message.edited,
          content: {
            message: {
              content: event.message.content,
            },
          },
          retracted: event.message.retraced,
          created_at: event.message.updatedAt,
          updated_at: event.message.createdAt,
        },
      };
    }

    draft.splice(
      draft.findIndex((chat) => chat.id === event.chatId),
      1,
    );
    draft.unshift(chat);
  });
  return nextState;
}

function onMessageCreateInConversation(
  currentState: ChatEntry[],
  eventData: OnMessageCreatedEvent,
) {
    const nextState = produce(currentState, (draft) => {
      if (eventData.message) {
        const message = {
          ...eventData.message,
          chatId: eventData.chatId,
          content: { message: eventData.message },
          reactions: {},
        };
        // @ts-ignore
        draft.push(message);
      }
    });
    return nextState;
}

function onChatUpdated(currentState: Chat[], eventData: OnChatUpdatedEvent): Chat[] {
  return produce(currentState, (draft) => {
    const chat = draft.find((chat) => chat.id === eventData.chat.id);
    const mappedChat = mapChatUpdatedEventToChat(eventData);

    if (!chat) {
      return;
    }

    draft.splice(
      draft.findIndex((chat) => chat.id === eventData.chat.id),
      1,
    );
    draft.unshift(mappedChat);
  });
}

function onChatCreated(currentState: Chat[], eventData: OnChatUpdatedEvent): Chat[] {
  return produce(currentState, (draft) => {
    const mappedChat = mapChatUpdatedEventToChat(eventData);

    draft.splice(
      draft.findIndex((chat) => chat.id === eventData.chat.id),
      1,
    );
    draft.unshift(mappedChat);
  });
}