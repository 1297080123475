import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage, IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useAuthStore } from '../../../stores/auth.store';
import { arrowBack } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Contact } from '../../../models/contact.model';
import { ContactList } from './ContactList';
import { URLS } from '../../../routing/urls';
import { useTranslation } from 'react-i18next';
import { useFetchContacts } from '../../../hooks/api/chat/useFetchContacts';

const ContactsPage: React.FC = (props: any) => {
  const history = props.history;
  const { t } = useTranslation();
  const { fetchContacts } = useFetchContacts();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [searchResults, setSearchResults] = useState<Contact[]>([]);
  const accessToken = useAuthStore((state) => state.accessToken);

  useEffect(() => {
    if (accessToken && !contacts.length) {
      fetchContacts().then((data) => {
        console.log('contacts', data);
        setContacts(data);
        setSearchResults(data);
      });
    }
  }, []);

  const handleSearch = (ev: Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();

    setSearchResults(contacts.filter(c => c.firstName.toLowerCase().indexOf(query) > -1 || c.lastName.toLowerCase().indexOf(query) > -1));
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot={'start'}>
            <IonButton routerLink={URLS.APP_CHATS} routerDirection="back" color="secondary" aria-label="Go back">
              <IonIcon icon={arrowBack} aria-label="Back icon"></IonIcon>
            </IonButton>
          </IonButtons>
          <IonTitle>{t('contacts')}</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar debounce={500} placeholder={t('search')} onIonChange={(ev) => handleSearch(ev)} />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ContactList history={history} contacts={searchResults ?? []} />
      </IonContent>
    </IonPage>
  );
};

export default ContactsPage;
