import PusherSrv, {Channel} from 'pusher-js/with-encryption';
import Pusher from "pusher-js/with-encryption";
import {getUidFromJwt} from "../utils/jwt";
import {config} from "../config/config";

let pusher:Pusher | null = null
let userChannel: Channel | null = null;
const chatChannels: {[chatId: string]: Channel} = {};
const definedUserListeners:string[] = [];

export function createPusherConnection(accessToken) {
    if (pusher) {
      return pusher;
    }
    pusher = new PusherSrv(config.pusher.id, {
      // @ts-ignore
      encryptionMasterKeyBase64: config.pusher.encryptionMasterKeyBase64,
      userAuthentication: {
        endpoint: config.pusher.endpointUserAuth,
        transport: 'ajax',
        params: {},
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
      channelAuthorization: {
        endpoint: config.pusher.endpointChannelAuth,
        transport: 'ajax',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
      cluster: 'eu',
    });
    // @ts-ignore
    pusher.signin();
    const userId = getUidFromJwt(accessToken)
    userChannel = pusher.subscribe(`private-encrypted-user_${userId}`);
    console.log('Connected to Pusher');
}

export function addListenerToChatChannel(chatId, eventName, callback){
  if (!pusher) {
    throw new Error('Pusher is not connected!')
  }

  if (!chatChannels[chatId]) {
    chatChannels[chatId] = pusher.subscribe(`private-chat_${chatId}`)
  }
  const chatChannel = chatChannels[chatId];
  chatChannel.bind(eventName, callback)
  return () => {
    userChannel?.unbind(eventName, callback)
  }
}

export function addListenerToUserChannel(eventName:string, callback) {
  if (!pusher) {
    throw new Error('Pusher is not connected!')
  }
  if (!userChannel) {
    throw new Error('UserChannel is not defined!')
  }
  if (definedUserListeners.includes(eventName)){
    //Don't need multiple listener to same event
    return
  }
  userChannel.bind(eventName, callback);
  definedUserListeners.push(eventName);

  return () => {
    userChannel?.unbind(eventName, callback);
    const index = definedUserListeners.findIndex((listener) => listener === eventName);
    if (index !== -1) {
      definedUserListeners.splice(index, 1);
    }
  }
}
