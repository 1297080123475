import { useCallback } from 'react';
import { useAuthStore } from '../../../stores/auth.store';
import { config } from '../../../config/config';
import { getUidFromJwt } from '../../../utils/jwt';
import { createConfig, createConfigSync, mbCoreApi } from '../api';
import axios from 'axios';

async function removePushDeviceToken(deviceToken): Promise<boolean> {
  const accessToken = useAuthStore.getState().accessToken;
  const userId = getUidFromJwt(accessToken);
  console.log('Removing push device token');
  try {
    await axios.delete(
      `${config.apiUrl}/v1/users/${userId}/deviceTokens/${deviceToken}`,
      createConfig(accessToken),
    );
    return true;
  } catch (e) {}
  return false;
}

export const useRemovePushToken = () => {
  const func = useCallback(async (deviceToken): Promise<boolean> => {
    if (!deviceToken  || deviceToken.trim === '') {
      return false;
    }
    const didDeleteToken = await removePushDeviceToken(deviceToken);
    return didDeleteToken;
  }, []);

  return {
    removePushToken: func,
  };
};
