import { sanitize } from 'dompurify';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { InfoPage } from '../../../models/info.model';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import './InfoPage.scss';
import { defaultSanitizeOptions } from '../../../utils/constants';

interface InfoPageModalProps {
  onDismiss: () => void;
  infoPage: InfoPage;
  orgName: string;
}

export const InfoPageModal = ({ onDismiss, infoPage, orgName }: InfoPageModalProps) => {
  const { t } = useTranslation(['common', 'infoPage']);

  const sanitizeFn = (dirty, options) => ({
    __html: sanitize(dirty, { ...defaultSanitizeOptions, ...options })
  })

  const sanitizeHTML = ({ html, options }) => (
    <p dangerouslySetInnerHTML={sanitizeFn(html, options)} />
  );

  return (
    <IonPage className="info-page-modal">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="medium" onClick={() => onDismiss()} aria-label="Go back">
              <IonIcon icon={chevronBackOutline} color="primary" aria-label="Back icon"></IonIcon>
              {t('back')}
            </IonButton>
          </IonButtons>
          <IonTitle>{orgName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h1 className="ion-no-margin">{infoPage.title}</h1>
        {sanitizeHTML({html: infoPage.content.body, options: {}})}
        <div>
          {!!infoPage.content.links.length && <h5 className="ion-margin-bottom"><b>{t('links', {ns: 'infoPage'})}</b></h5>}
          {infoPage.content.links.map((link, index) => (
            <IonRouterLink key={index} href={link.url} target="_blank" color="dark" className="link-item">
              <IonIcon icon={chevronForwardOutline} color="primary" aria-label="Link icon"></IonIcon>
              {link.title}
            </IonRouterLink>
          ))}
        </div>
      </IonContent>
    </IonPage>
  )
}
