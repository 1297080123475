import { useEffect, useState } from 'react';
import { App } from '@capacitor/app';
import { Badge } from '@capawesome/capacitor-badge';
import {useAppSateStore} from "../stores/appState.store";

export function AppStateListener({ children }) {
  const setAppActiveState = useAppSateStore((state) => state.setActive);

  useEffect(() => {
    console.log('App started!')
    setAppActiveState(true)

    App.addListener('appStateChange', ({ isActive }) => {
      console.log('App state changed. Is active? ', isActive);
      setAppActiveState(isActive)
    });

    App.addListener('resume', () => {
      console.log('App resumed');
    });

    App.addListener('pause', () => {
      console.log('App paused');
    });

    App.addListener('appRestoredResult', data => {
      console.log('Restored state: ', data);
    });

  }, []);

  return <>{children}</>;
}
