import {
  IonButton,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonSpinner,
  useIonRouter,
  isPlatform,
} from '@ionic/react';
import './Login.scss';

import * as iab from '@awesome-cordova-plugins/in-app-browser';
import { useEffect, useState } from 'react';
import { AuthState, useAuthStore } from '../../../stores/auth.store';
import { URLS } from '../../../routing/urls';
import { config } from '../../../config/config';

const Signup = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const router = useIonRouter();
  const authState = useAuthStore((state) => state.authState);
  const openWebview = async () => {
    const browserRef = await iab.InAppBrowser.create(
      config.loginUrl,
      '_blank',
      {
        location: 'no',
        clearsessioncache: 'yes',
        clearcache: 'yes',
      },
    );
    const loadStart$ = browserRef.on('loadstart').subscribe((event: any) => {
      let url = event.url;
      if (url.indexOf('tgt') !== -1) {
        let tgt = url.split('?')[1].split('=')[1];
        window.location.replace(`/auth/callback?tgt=${tgt}`);
        browserRef.close();
        loadStart$.unsubscribe();
      }
    });
  };

  useEffect(() => {
    if (authState === AuthState.loggedIn) {
      if (isPlatform('cordova')) {
        console.log({ routeInfo: router.routeInfo });
        setTimeout(() => {
          router.push(URLS.APP_CHATS, 'root', 'replace');
        }, 1);
      } else {
        window.location.replace(URLS.APP_CHATS);
      }
    } else {
      setShowSpinner(false);
    }
  }, [authState]);

  return (
    <IonPage className="login-page">
      <IonContent fullscreen>
        <div className="login-page-wrapper">
          <IonImg src="/assets/bowl.png" />
          {showSpinner ? (
            <IonGrid fixed={true}>
              <IonRow>
                <IonCol style={{ textAlign: 'center' }}>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{ textAlign: 'center' }}>
                  <IonSpinner></IonSpinner>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <div className="content-box">
              <IonGrid>
                <IonRow
                  className={`ion-text-center ion-justify-content-center`}
                >
                  <IonCol size="11">
                    <IonCardTitle>Transponder Elev</IonCardTitle>
                  </IonCol>
                </IonRow>
                <IonRow
                  className={`ion-text-center ion-justify-content-center ion-margin-top`}
                >
                  <IonCol>
                    {isPlatform('cordova') ? (
                      <IonButton onClick={openWebview} size="large">
                        Logg inn
                      </IonButton>
                    ) : (
                      <IonButton href={config.loginUrl} size="large">
                        Logg inn
                      </IonButton>
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Signup;
