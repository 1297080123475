import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonPage,
  IonSearchbar, IonText,
  IonTitle,
  IonToolbar,
  useIonModal,
} from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './InfoList.scss';
import { CategoryVM, InfoPage, InfoPageViewModel } from '../../../models/info.model';
import {
  mapInfoPageListToInfoPageVMList,
} from '../../../mappers/mapInfoPageListToInfoPageVMList';
import { chevronForwardOutline } from 'ionicons/icons';
import { InfoPageModal } from './infoPage';
import { useFetchPages } from '../../../hooks/api/info/useFetchPages';

interface InfoPageEntryProps {
  entry: CategoryVM;
  orgName: string;
}
const InfoPageEntry: FC<InfoPageEntryProps> = ({entry, orgName}) => {
  const [selectedInfoPage, setSelectedInfoPage] = useState<InfoPage>();
  const [present, dismiss] = useIonModal(InfoPageModal, {
    onDismiss: () => dismiss(),
    infoPage: selectedInfoPage,
    orgName: orgName,
  });

  const showInfoPageDetail = (ip: InfoPage) => {
    setSelectedInfoPage(ip);
    present({
      onWillDismiss: () => {
        //
      },
    });
  }

  return (
    <IonAccordion value={entry.categoryName}>
      <IonItem slot="header" lines="full" role="none">
        <IonLabel className="ion-padding-start" aria-label={entry.categoryName}>{entry.categoryName}</IonLabel>
      </IonItem>
      <div className="ion-padding" slot="content" role="none">
        {entry.infoPages.map((ip) => (
          <div className="info-page-entry-title" key={ip.id} onClick={() => showInfoPageDetail(ip)}>
            <IonIcon icon={chevronForwardOutline} color="primary" aria-label="Info page icon"></IonIcon>
            {ip.title}
          </div>
        ))}
      </div>
    </IonAccordion>
  )
};

const InfoList: FC = () => {
  const { t } = useTranslation(['common', 'infoPage']);
  const { fetchPages } = useFetchPages();

  const [infoPages, setInfoPages] = useState<InfoPage[]>([]);
  const [infoPagesVM, setInfoPagesVM] = useState<InfoPageViewModel[]>([]);

  useEffect(() => {
    fetchPages().then((pages) => {
      setInfoPages(pages);
      setInfoPagesVM(mapInfoPageListToInfoPageVMList(pages));
    });
  }, []);

  const handleSearch = (ev: Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();

    setInfoPagesVM(mapInfoPageListToInfoPageVMList(infoPages, query));
  }

  return (
    <IonPage className="info-list-page">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle><h1 className="ion-no-margin">{t('info', {ns: 'infoPage'})}</h1></IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            debounce={500}
            placeholder={t('findInfoPage', {ns: 'infoPage'})}
            onIonChange={(ev) => handleSearch(ev)}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {!infoPages.length && (
          <IonText color="medium">
            <p className="ion-padding">{t('noPages', {ns: 'infoPage'})}</p>
          </IonText>
        )}
        <IonList role="none">
          {infoPagesVM.map((ip, index) => (
            <IonItemGroup key={index}>
              <IonItemDivider>
                <IonLabel><h2>{ip.organizationName || ip.federationName}</h2></IonLabel>
              </IonItemDivider>
              {ip.categories.map((categoryVm) => (
                <IonAccordionGroup key={categoryVm.categoryName}>
                  <InfoPageEntry entry={categoryVm} orgName={ip.organizationName || ip.federationName!} />
                </IonAccordionGroup>
              )) }
            </IonItemGroup>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default InfoList;
