import { useEffect, useState } from 'react';
import { AuthState, useAuthStore } from '../stores/auth.store';

export function StartListener({ children }) {
  const [isReady, setIsReady] = useState(false);
  const authState = useAuthStore((state) => state.authState);

  useEffect(() => {
    if (!isReady && authState !== AuthState.notInitialized) {
      setIsReady(true);
    }
  }, [authState]);

  return <>{isReady ? children : null}</>;
}
