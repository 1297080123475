import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// THIS store the appstate
interface AppStateStore {
  active: boolean;
  setActive: (isActive: boolean) => void;
}
const appStateStore = (set) => ({
  active: false,
  setActive: (isActive:boolean) => set({ active:isActive }),
});

export const useAppSateStore = create<AppStateStore>()(
  devtools(appStateStore, {
    name: 'AppStateStore',
  }),
);
