import jwt_decode, {JwtPayload} from "jwt-decode";
import moment from 'moment';

export const getUidFromJwt = (jwt) => {
  const dateNow = new Date();

  try{
    if (jwt) {
      const decoded =  jwt_decode(jwt) as JwtPayload;
      if (decoded.exp! < dateNow.getTime()/1000){
        return null;
      }
      if (decoded.sub){
        return decoded.sub
      }
    }
  } catch (e) {}
  return null;
}

export const checkIsTokenEligible = (jwt) => {
  const now = moment().unix();

  if (!jwt) {
    return false;
  }

  const decoded = jwt_decode(jwt) as JwtPayload;

  return decoded && decoded.exp! >= now;
}

export const calculateReissueLimit = (jwtDecoded, factor = 0.9) => {
  const totalLifetime = jwtDecoded.exp - jwtDecoded.iat;

  return jwtDecoded.exp - totalLifetime * (1 - factor);
}
