import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { secureStorage } from './secureStorage';

export enum AuthState {
  notInitialized = 'notInitialized',
  loggedIn = 'loggedIn',
  loggedOut = 'loggedOut',
  temporaryLoggedOut = 'temporaryLoggedOut',
}

interface AuthStateI {
  authState: AuthState;
  accessToken: string;
  sessionToken: string;
  setAccessToken: (accessToken: string) => void;
  setSessionToken: (sessionToken: string) => void;
  setAuthState: (authState: AuthState) => void;
}
const authStore = (set) =>
  <AuthStateI>{
    authState: AuthState.notInitialized,
    sessionToken: '',
    accessToken: '',
    setAccessToken: (accessToken) => set({ accessToken }),
    setSessionToken: (sessionToken) => set({ sessionToken }),
    setAuthState: (authState) => set({ authState }),
  };

export const useAuthStore = create<AuthStateI>()(
  devtools(
    persist(authStore, {
      name: 'auth-state',
      storage: createJSONStorage(() => secureStorage),
      onRehydrateStorage: () => (state) => {
        if (state && state.authState === AuthState.notInitialized) {
            state.setAuthState(AuthState.loggedOut);
        }
      }
    }),
    { name: 'AppSettingsState' },
  ),
);
