import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Account, Organization } from '../models/user.model';

const REDUX_ACTION_PREFIX = "userStore."
// THIS store is only lives within a session (starts from first request when you are logged in to device off or close browser or temporarily logged out (pin code))

interface OrganizationsDictionary {
  [key: string]: Organization;
}
interface UserState {
  accounts: Account[];
  setAccounts: (accounts: Account[]) => void;
  organizations: OrganizationsDictionary;
  setOrganizations: (organizations: OrganizationsDictionary) => void;
}
const userStore = (set, get) => ({
  accounts: [],
  setAccounts: (accounts: Account[]) => set({ accounts }, false, REDUX_ACTION_PREFIX + 'setAccounts'),
  organizations: null,
  setOrganizations: (organizations: OrganizationsDictionary) => set({ organizations }, false, REDUX_ACTION_PREFIX + 'setOrganizations'),
});

export const useUserStore = create<UserState>()(
// @ts-ignore
  devtools(userStore, {
    name: 'userStore',
  }),
);

