import { useCallback } from 'react';
import { config } from '../../../config/config';
import { Chat } from '../../../models/chat.model';
import { mbCoreApi } from '../api';

export const useFetchChats = () => {
  const func = useCallback(async (): Promise<Chat[]> => {
    try {
      console.log('fetching chats....');

      const response = await mbCoreApi.get(`${config.apiUrl}/v1/chats`);

      if (!response || !response.data) {
        return Promise.reject(new Error('useFetchChats: No data'));
      }

      return response.data;
    } catch (e: any) {
      throw new Error('useFetchChats:', e);
    }
  }, []);

  return {
    fetchChats: func,
  };
};
