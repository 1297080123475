import { Chat } from '../models/chat.model';

export function isUserInChatAlone(chat: Chat): boolean {
  return chat.participantCount === 1 && chat.firstParticipants[0].participant.id === chat.myContext.participantId;
}

export function isChatAnonymous(chat: Chat): boolean {
  return chat.firstParticipants.some(p => p.participant.anonymous);
}

export function getChatName(chat: Chat): string {
  if (chat.name) {
    return chat.name;
  }

  const chatName = chat.firstParticipants
    .filter(p => p.participant.id !== chat.myContext.participantId)
    .map(p => p.participant.name).join(', ');

  if (isChatAnonymous(chat)) {
    const me = chat.firstParticipants.find(p => p.participant.id === chat.myContext.participantId)!;
    if (me.participant.anonymous) {
      // if I'm anonymous user - can see others participants
      return chatName;
    } else {
      // if my conversation partner is anonymous - I can't see his name
      const chatId = chat.id.slice(-4);
      return `Inkognito chat ID: '${chatId}'`;
    }
  }

  return chatName;
}
