import { useCallback } from 'react';
import { config } from '../../../config/config';
import { createConfig, mbCoreApi } from '../api';
import { useAuthStore } from '../../../stores/auth.store';
import { getUidFromJwt } from '../../../utils/jwt';
import { Helper } from '../../../models/helper.model';

export const useFetchHelpers = () => {
  const accessToken = useAuthStore(state => state.accessToken);

  const func = useCallback(async (): Promise<Helper[]> => {
    if (!accessToken) {
      throw new Error('useFetchHelpers: No accessToken!');
    }

    try {
      console.log('fetching helpers....');

      const userId = getUidFromJwt(accessToken);
      const response = await mbCoreApi.get(
        `${config.apiUrl}/v1/users/${userId}/chatHelperAccounts`,
        createConfig(accessToken),
      );

      if (!response || !response.data) {
        return Promise.reject(new Error('useFetchHelpers: No data'));
      }

      return response.data;
    } catch (e: any) {
      throw new Error('useFetchHelpers:', e);
    }
  }, [accessToken]);

  return {
    fetchHelpers: func,
  };
};
