// Custom storage object
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

import { StateStorage } from 'zustand/middleware/persist';

export const secureStorage: StateStorage = {
  getItem: (name: string): Promise<string | null> => {
    return new Promise((res, rej) => {
      SecureStoragePlugin.get({key:name}).then(({value}) => {
        res(value)
      }).catch(() => {
        res(null)
      })
    })
  },
  setItem: (name: string, value: string): Promise<void> => {
    return new Promise((res, rej) => {
      SecureStoragePlugin.set({key:name, value}).then(() => {
        res()
      }).catch((e) => {
        console.log(e.message);
      })
    })
  },
  removeItem: (name: string): Promise<void> => {
    return new Promise((res, rej) => {
      SecureStoragePlugin.remove({key:name}).then(() => {
        res();
      })
    })
  },
};
