import { useCallback } from 'react';
import { config } from '../../../config/config';
import { mbCoreApi } from '../api';
import { ChatEntry } from '../../../models/conversation.model';

export const useFetchMessages = () => {
  const func = useCallback(async (id: string): Promise<ChatEntry[]> => {
    try {
      const requestUrl = `${config.apiUrl}/v1/chats/${id}`;
      console.log('fetching chat messages: ' + requestUrl);

      const response = await mbCoreApi.get(`${config.apiUrl}/v1/chats/${id}/messages`);

      if (!response || !response.data) {
        return Promise.reject(new Error('useFetchMessages: No data'));
      }

      return response.data;
    } catch (e: any) {
      throw new Error('useFetchMessages:', e);
    }
  }, []);

  return {
    fetchMessages: func,
  };
};
