import { useEffect } from 'react';
import { addListenerToUserChannel, createPusherConnection } from '../providers/pusher.provider';
import { useAuthStore } from '../stores/auth.store';
import { PusherHandler } from '../logic/PusherHandler';
import { useEventPusherStore } from '../stores/pusher.store';

export function Pusher({ children }) {
  const accessToken = useAuthStore((state) => state.accessToken);
  const addEvent = useEventPusherStore((state) => state.addEvent);
  const setLastEvent = useEventPusherStore((state) => state.setLastEvent);

  useEffect(() => {
    createPusherConnection(accessToken);

    const pusherEvents = [
      'message-created',
      'message-updated',
      'chat-created',
      'chat-updated',
      'chat-read',
    ];
    pusherEvents.forEach((eventName) => {
      addListenerToUserChannel(eventName, (data) => {
        addEvent(eventName, data);
        setLastEvent(eventName, data);
      });
    });
  }, []);

  return <PusherHandler>{children}</PusherHandler>;
}
