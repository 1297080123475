import { useCallback } from 'react';
import { config } from '../../../config/config';
import { createConfig, mbCoreApi } from '../api';
import { Contact } from '../../../models/contact.model';
import { useAuthStore } from '../../../stores/auth.store';
import { getUidFromJwt } from '../../../utils/jwt';

export const useFetchContacts = () => {
  const accessToken = useAuthStore(state => state.accessToken);

  const func = useCallback(async (): Promise<Contact[]> => {
    if (!accessToken) {
      throw new Error('useFetchContacts: No accessToken!');
    }

    try {
      console.log('fetching contacts....');

      const userId = getUidFromJwt(accessToken);
      const response = await mbCoreApi.get(
        `${config.apiUrl}/v1/users/${userId}/chatAccounts`,
        createConfig(accessToken),
      );

      if (!response || !response.data) {
        return Promise.reject(new Error('useFetchContacts: No data'));
      }

      return response.data;
    } catch (e: any) {
      throw new Error('useFetchContacts:', e);
    }
  }, [accessToken]);

  return {
    fetchContacts: func,
  };
};
