import { OnChatUpdatedEvent } from "../models/pusherEvent.model";
import { Chat } from "../models/chat.model";

export function mapChatUpdatedEventToChat(data: OnChatUpdatedEvent): Chat {
  const { chat } = data;

  return {
    ...chat,
    myContext: {
      pinned: false,
      participantId: chat.myContext.participant.id,
      unread: false,
    },
    lastMessage: {
      sender: chat.lastMessage.sender,
      message: {
        ...chat.lastMessage.message,
        created_at: chat.lastMessage.message.createdAt,
        updated_at: chat.lastMessage.message.updatedAt,
      }
    },
    firstParticipants: chat.firstParticipants.map((p, index) => ({
      participant: p.participant,
      id: index,
    }))
  }
}