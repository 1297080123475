import { useEffect } from 'react';
import { AuthState, useAuthStore } from '../stores/auth.store';
import { usePushNotificationStore } from '../stores/pushNotification.store';
import { useIonRouter } from '@ionic/react';
import { useRemovePushToken } from '../hooks/api/pushToken/useRemovePushToken';
import { URLS } from '../routing/urls';

export function LogoutListener({ children }) {
  const { removePushToken } = useRemovePushToken();
  const setAccessToken = useAuthStore((state) => state.setAccessToken);
  const setSessionToken = useAuthStore((state) => state.setSessionToken);
  const setAuthState = useAuthStore((state) => state.setAuthState);
  const setDeviceToken = usePushNotificationStore(
    (state) => state.setDeviceToken,
  );
  const router = useIonRouter();
  useEffect(() => {
    const unsubscribe = useAuthStore.subscribe((state, prevState) => {
      const authState = state.authState;
      const prevAuthState = prevState.authState;
      const deviceToken =
        usePushNotificationStore.getState()?.deviceToken ?? null;
      if (
        prevAuthState !== AuthState.notInitialized &&
        authState !== prevAuthState &&
        authState === AuthState.loggedOut
      ) {
        removePushToken(deviceToken).then((r) => {
          setDeviceToken('');
          setAccessToken('');
          setSessionToken('');
          setAuthState(AuthState.loggedOut);
          setTimeout(() => {
            window.location.replace(URLS.LOGIN);
          }, 100);
        });
      }
      return unsubscribe;
    });
  }, []);

  return <>{children}</>;
}
