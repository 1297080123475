import { Helper, HelperListGroup } from '../models/helper.model';
import _ from 'lodash';

export const mapHelperListToHelperListGroup = (helpers: Helper[], searchKey?: string): HelperListGroup[] => {
  if (!helpers?.length) {
    return [];
  }

  const filteredAudience = helpers.filter(
    (h) =>
      `${h.firstName} ${h.lastName}`.toLowerCase().indexOf(searchKey?.toLowerCase() ?? '') !== -1
      || (h.helperInfo?.title || '').toLowerCase().indexOf(searchKey?.toLowerCase() ?? '') !== -1
      || (h.helperInfo?.description || '').toLowerCase().indexOf(searchKey?.toLowerCase() ?? '') !== -1
  )
    .sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`));

  const orgMap = _.groupBy(filteredAudience, 'organizationId');
  const result: HelperListGroup[] = [];
  let people: Helper[];
  Object.keys(orgMap).forEach((orgId) => {
    people = orgMap[orgId];
    if (people.length) {
      result.push({
        organizationName: people[0].organizationName,
        entries: people.map(p => p),
      });
    }
  });

  return result;
}
