import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Login from '../pages/open/login/Login';
import Secure from '../pages/secure/Secure';
import { Auth } from '../pages/open/auth/Auth';
import { URLS } from './urls';
import { Listeners } from '../listeners/listeners';

export function AppRouting() {
  return (
    <IonApp>
      <Listeners>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route exact path={URLS.AUTH_CALLBACK} component={Auth} />
            <Route exact path={URLS.LOGIN} component={Login} />
            <Route path={URLS.APP} component={Secure} />
            <Redirect exact from="/" to={URLS.LOGIN} />
          </IonRouterOutlet>
        </IonReactRouter>
      </Listeners>
    </IonApp>
  );
}
