import { Conversation } from './Conversation';
import {useEffect} from "react";
import {addListenerToChatChannel} from "../../../../providers/pusher.provider";
import {useEventPusherStore} from "../../../../stores/pusher.store";
import {useChatsStore} from "../../../../stores/chats.store";
import {useAuthStore} from "../../../../stores/auth.store";
import { IonLoading } from '@ionic/react';
import { useFetchChats } from '../../../../hooks/api/chat/useFetchChats';
import {useAppSateStore} from "../../../../stores/appState.store";
import * as _ from "lodash";

const ConversationPage: React.FC = (props: any) => {
  const { fetchChats } = useFetchChats();
  const chatId = props.match?.params?.id;
  const addEvent = useEventPusherStore(state => state.addEvent)
  const setLastEvent = useEventPusherStore(state => state.setLastEvent)
  const appIsActive = useAppSateStore((state) => state.active);
  const chatEntries = useChatsStore(state => state.chatEntries);

  const chats = useChatsStore((state) => state.chats);
  const setChats = useChatsStore((state) => state.setChats);
  const accessToken = useAuthStore((state) => state.accessToken);

  useEffect(() => {
    if (appIsActive && accessToken && !chats) {
      fetchChats().then((newChats) => {
        if (!_.isEqual(chats, newChats)) {
          setChats(newChats);
        }
      });
    }
  }, [appIsActive]);

  useEffect(() => {

    if (!chatEntries[chatId]) {
      console.log('Start subscribing to channel events ');
      ['chat-read', 'participant-added', 'participant-left', 'reactions-updated'].forEach(eventName => {
        addListenerToChatChannel(chatId,
            eventName,
            (data) => {
              addEvent(eventName, data)
              setLastEvent(eventName, data)
            },
        )
      });
    }
  }, []);

  if (!chats) {
    return <IonLoading isOpen />
  }

  return (
      <Conversation chatId={chatId} />
  );
};

export default ConversationPage;
