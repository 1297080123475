import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

// THIS stores automatically to localstorage. Please only store simple stuff like preference and settings
interface AppSettingsState {
  darkMode: boolean;
  language: string;
  toggleDarkMode: () => void;
  setLanguage: (newLang: string) => void;
}
const appSettingStore = (set) => ({
  darkMode: false,
  language: 'no',
  toggleDarkMode: () => set((state) => ({ darkMode: !state.darkMode })),
  setLanguage: (newLang) => set({ language: newLang }),
});

export const useAppSettingsStore = create<AppSettingsState>()(
  devtools(
    persist(appSettingStore, {
      name: 'app-settings',
    }),
    { name: 'AppSettingsState' },
  ),
);
